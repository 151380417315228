<template>
  <div>
    <b-form-group label="บัญชี">
      <b-form-select
        v-model="form.bookbank_selected"
        :options="bookbank_options"
        @input="selected"
      >
      </b-form-select>
    </b-form-group>
    <!-- <b-form-group label="วันที่">
            <date-picker
                v-model="form.date_selected"
                :masks="{ input: 'D/M/YYYY' }"
                :model-config="modelConfig"
                mode = 'date'
                @input="selected"
            >
                <template v-slot="{ inputValue, togglePopover }">
                    <b-form-group>
                        <b-form-input
                            class="border px-2 py-1 rounded"
                            :value="inputValue"
                            @click="togglePopover()"
                            autocomplete="off"
                        />
                    </b-form-group>
                </template>
            </date-picker>
        </b-form-group> -->
  </div>
</template>
<script>
import {
  BLink,
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BFormGroup,
  BFormInput,
  BFormSelect,
} from "bootstrap-vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";

export default {
  components: {
    BLink,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormGroup,
    BFormInput,
    DatePicker,
    BFormSelect,
  },
  data() {
    return {
      bookbank_options: [{ value: null, text: "Please select an option" }],
      form: {
        bookbank_selected: null,
      },
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
    };
  },
  created() {
    this.loadBookbank();
    this.selected();
  },
  methods: {
    loadBookbank() {
      this.$http({
        method: "GET",
        url: `/bookbank/list`,
      }).then((x) => {
        this.bookbank_options = x.data.data.mix;
        this.bookbank_options = this.bookbank_options.filter((x) => x.active);

        if (this.bookbank_options.length > 0) {
          const select_index =
            this.bookbank_options.findIndex(
              (account) => account.t_account_number === "2892218382"
            ) === -1
              ? 0
              : this.bookbank_options.findIndex(
                  (account) => account.t_account_number === "2892218382"
                );
          this.form.bookbank_selected =
            this.bookbank_options[select_index].value; /// default scb
        }

        let select_all = {
          display_name: "ทั้งหมด",
          type: "all",
          active: true,
          is_delete: false,
          text: "ทั้งหมด",
          value: "all",
        };

        this.bookbank_options.unshift(select_all);
      });
    },
    selected() {
      this.$emit("selected", this.form);
    },
  },
};
</script>
